
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































































































$bp: s;

.text-picture,
[class*='text-picture--'] {
  @include mq($bp) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    &.is-reverse {
      flex-direction: row;
    }
  }
}

.text-picture__picture {
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: (31 / 34) * 100%;
  }

  ::v-deep img {
    @include image-fit;

    bottom: auto;
    height: calc(100% + 100px);
  }

  @include mq($bp) {
    width: col(8, 16);
    margin-bottom: 0;

    &::before {
      padding-top: (51 / 40) * 100%;
    }
  }
}

.text-picture__content {
  @include mq($bp) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: col(6, 16);
  }
}

.text-picture__content__title {
  @extend %fw-normal;

  // prettier-ignore
  @include fluid(font-size,(xxs: 24px, xxl: 64px));
  // prettier-ignore
  @include fluid(line-height,(xxs: 28px, xxl: 70px));
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 10px, xxl: 40px));

  font-family: $ff-alt;
}

.text-picture__content__text {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 20px, xxl: 40px));
}

.text-picture__content__link {
  display: inline-flex;
  align-items: center;
  overflow: hidden;

  svg {
    width: 0.6rem;
    margin-right: 1rem;
  }
}

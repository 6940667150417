
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































































.slider,
[class*='slider--'] {
  &:focus {
    outline: none;
  }

  ::v-deep .flickity-viewport {
    width: 100%;

    &:hover {
      cursor: grabbing;
    }
  }
}

.slider__card {
  width: col(10, 18);
  min-width: 29rem;
  padding-right: col(2, 18);

  .is-ready & {
    position: absolute;
  }
}
